// @flow
import * as React from 'react';
// this is required in runtime, webpack plugin provides it for buildtime only
import 'regenerator-runtime/runtime';
import {
  sbChannelList,
  sbGetInstance,
  sbGetMessagesContainer,
  sbSendTextMessage,
  sbGetGroupChannel,
  sbGroupChannelExist,
  sbCreateGroupChannelByName,
  sbGetMessageList,
  sbMarkAsReadByURL,
} from 'sendbird-utils';
import { messageReceiveFactory, channelsToThreads, type ThreadsItemType } from 'sendbird-utils/lib/imAdapters';
import { select, put, call, all, take, delay, race } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import {
  ChatAdapter,
  loadMessage,
  MessageAdapter,
  MessageFactory,
  createChannel,
  channelExists,
  DOCUMENT_CHAT_P2P_TYPE,
  makeChannelName,
  ChatWorkerThreadsAdapter,
  sbInstance,
} from 'domain/chat/helpers';
import { userSelector, userIdSelector } from 'domain/env';
import Api, { ensure, logoutAction } from 'domain/api';
import CONSTANTS from 'domain/constants';
import { toast } from 'react-toastify';
import ChatToast, {
  ChatToastClose,
  ChatToastClasses,
} from 'components/mui/Layouts/components/Chat/components/ChatToast';
import type {
  SendBirdInstance,
  GroupChannel,
  GroupChannelListQuery,
  UserMessage,
  PreviousMessageListQuery,
} from 'sendbird';
import { companiesByIdSelector } from 'domain/companies/companiesSelector';
import { currentCompanySelector } from 'domain/documents/documentSelector';
import { chatParticipantsSelector } from 'domain/chat/chatSelector';
import { notify } from 'lib/errorLogger';
import * as actions from './chatActions';
import * as selectors from './chatSelector';
import { isMobile } from 'lib/systemHelpers/browserHelpers';
import flow from 'lodash/fp/flow';
import partial from 'lodash/fp/partial';

export const getCompanyChatContext = ensure(Api.getCompanyChatContext, actions.fetchCompanyChatUsers, {
  adapter: (resp) => ChatAdapter(resp.data),
  tokenType: 'company',
});

function* getMessageQuery(name: string): Saga<?PreviousMessageListQuery> {
  const mqs: Map<string, PreviousMessageListQuery> = yield select(selectors.messageQuerySelector);
  return mqs.get(name);
}

function* getChannelHistory(channel: GroupChannel, name: string): Saga<*> {
  const mq: PreviousMessageListQuery = yield call(getMessageQuery, name);
  if (!mq) {
    const messageListQuery: PreviousMessageListQuery = channel.createPreviousMessageListQuery();
    try {
      const messages = yield call(loadMessage, messageListQuery);
      channel.markAsRead();
      yield put({
        type: actions.createChannelAction.success,
        payload: {
          name,
          messages: MessageAdapter(messages),
          channel,
          messageQuery: messageListQuery,
        },
      });
    } catch (err) {
      yield put({
        type: actions.createChannelAction.failure,
        err,
      });
    }
  }
}

type ChannelAction = {
  payload: {
    users: string[],
    name: string,
    distinct: boolean,
    data?: ?string,
    customType?: ?string,
  },
};

export function* ensureCreateCannel({ payload }: ChannelAction): Generator<*, void, *> {
  const defaults = {
    distinct: false,
    data: null,
    customType: null,
    coverUrlOrImageFile: '',
  };
  const channelOptions = { ...defaults, ...payload };
  const sb: SendBirdInstance = sbGetInstance();
  sbInstance(sb);
  const channels = yield channelExists(sb, payload.name);
  // this check can be removed later, as we are already filtering by channel name in sendbird query
  let channel: GroupChannel = channels.find((ch) => ch.name === payload.name);
  if (!channel) {
    channel = yield createChannel(
      sb,
      channelOptions.users,
      channelOptions.distinct,
      channelOptions.name,
      channelOptions.coverUrlOrImageFile,
      channelOptions.data,
      channelOptions.customType,
    );
  }
  yield call(getChannelHistory, channel, payload.name);
}

export function* markAsReadChannel({ payload: { name } }): Saga<*> {
  if (name !== null) {
    const channels: $ReadOnlyArray<GroupChannel> = yield call(sbGroupChannelExist, name);
    if (Array.isArray(channels) && channels.length > 0) {
      channels[0].markAsRead();
      // might be better to retrieve updated channel instead of updating in reducer
      yield put({
        type: actions.markChannelAsReadAction.type,
        payload: { name },
      });
    }
  }
}

function notifyWithToast(
  channelName: string,
  message?: UserMessage,
  companyName: ?string = null,
  // eslint-disable-next-line no-unused-vars
  messageFromCurrentCompany: boolean = true, // dont affect yet
) {
  const options = {
    className: ChatToastClasses.wrapper,
    progressClassName: ChatToastClasses.progress,
    type: toast.TYPE.SUCCESS,
    closeButton: <ChatToastClose />,
  };

  if (arguments.length === 4 && !isMobile) {
    toast(<ChatToast message={message} channelName={channelName} companyName={companyName} group />, options);
  } else {
    toast(<ChatToast channelName={channelName} message={message} />, options);
  }
}

export function threadSwetch(userId: string) {
  const ENV = process.env.REACT_APP_ENV || 'local';
  return (c: GroupChannel, message?: UserMessage) => {
    const isNew = typeof message !== 'undefined';
    const isOwnMwssage = isNew && message.sender.userId === userId;
    // @to-do check if data is present on old messages to avoid parse error
    const { companyId } = JSON.parse((message || c.lastMessage).data);
    const params = c.name.split('#');

    if (ENV === params[0]) {
      if (c.customType === DOCUMENT_CHAT_P2P_TYPE) {
        return {
          type: actions.documentAddThreadAction.type,
          payload: {
            companyId, // use companyId from message, not channel
            name: c.name,
            message: MessageFactory(message || c.lastMessage),
            unreadMessageCount: c.unreadMessageCount,
            channel: c,
            newMessage: isNew,
            isOwnMwssage,
          },
        };
      }

      return {
        type: actions.receiveMessageAction.success,
        payload: {
          companyId,
          message: MessageFactory(message || c.lastMessage),
          unreadMessageCount: c.unreadMessageCount,
          name: c.name,
          channel: c,
          newMessage: isNew,
          isOwnMwssage,
        },
      };
    }

    return {
      type: '@CHAT/OTHER_ENV',
      payload: params[0],
    };
  };
}

export function* ensureAllChannel(): Generator<*, void, *> {
  const userId = yield select(userIdSelector);
  const channels: GroupChannel[] = yield sbChannelList();
  const switcher = threadSwetch(userId);
  yield all(
    channels.map((c: GroupChannel) => {
      // isolate error in particular channel to allow
      // further channels to be processed
      try {
        return put(switcher(c));
      } catch (err) {
        return put({ type: 'CHAT/CHANNEL_PROCESSING_ERROR', payload: { err, c } });
      }
    }),
  );
}

type SendMessageAction = {
  payload: {
    message: string,
    threadId: string,
    params: {
      companyId: string,
      documentId?: string,
    },
  },
};

// eslint-disable-next-line max-len
export function ensureChannelExists({
  channelName,
  sb,
}: {
  channelName: string,
  sb: SendBirdInstance,
}): Promise<Array<GroupChannel>> {
  const channelListQuery: GroupChannelListQuery = sb.GroupChannel.createMyGroupChannelListQuery();
  channelListQuery.channelNameContainsFilter = channelName;
  channelListQuery.includeEmpty = true;
  return new Promise((resolve, reject) => {
    if (channelListQuery.hasNext) {
      channelListQuery.next((retrievedChannelList: Array<GroupChannel>, error) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(retrievedChannelList);
      });
    }
  });
}

// eslint-disable-next-line
export function* markAllDocumentChatsRead({
  companyId,
  documentId,
}: {
  companyId: string,
  documentId: string,
}): Generator<*, void, *> {
  let participants = yield select(chatParticipantsSelector);
  if (participants.size === 0) {
    yield* getCompanyChatContext();
    participants = yield select(chatParticipantsSelector);
  }

  const cUser = yield select(userSelector);
  yield all(
    participants.toArray().map((participant) =>
      call(markAsReadChannel, {
        payload: {
          name: makeChannelName([participant.userId, cUser.userId], companyId, documentId),
          companyId,
        },
      }),
    ),
  );
}

// eslint-disable-next-line max-len
export function* getDocumentChatNewContext({
  companyId,
  documentId,
}: {
  companyId: string,
  documentId: string,
}): Generator<*, void, *> {
  let participants = yield select(chatParticipantsSelector);
  if (participants.size === 0) {
    yield* getCompanyChatContext();
    participants = yield select(chatParticipantsSelector);
  }
  const sb: SendBirdInstance = sbGetInstance();
  sbInstance(sb);

  const cUser = yield select(userSelector);
  const channelNames = participants.map((participant) =>
    makeChannelName([participant.userId, cUser.userId], companyId, documentId),
  );

  let channels: Array<GroupChannel> = [];
  for (let i = 0; i < channelNames.size; i += 1) {
    const ch = yield call(ensureChannelExists, { channelName: channelNames.get(i), sb });
    channels = [...channels, ...ch];
  }
  if (channels instanceof Array && channels.length > 0) {
    for (let i = 0; i < channels.length; i += 1) {
      yield call(getChannelHistory, channels[i], channelNames.get(i));
    }
  }
}

// =======================

const messageReceive = messageReceiveFactory(CONSTANTS.BUILD_ENV);

export function* ensureActiveChannel({ payload }: { payload: { name: string } }): Saga<*> {
  if (payload.name) {
    const channelUrl = (yield select(selectors.chatThreadSelector)).getIn([payload.name, 'url']);
    const listQuery = (yield select(selectors.messageQuerySelector)).get(payload.name);
    //* * when sb inctance connected with server we can get  currentUser information */
    //* * https://sendbird.com/docs/chat/v3/javascript/guides/authentication#2-connect-to-sendbird-server-with-a-user-id */
    if (!sbGetInstance().currentUser) {
      yield race({
        timeout: delay(5000),
        cancel: take(actions.chatConnectAction.success),
      });
    }
    try {
      if (channelUrl) {
        const { query, messages, channel } = yield call(sbGetMessagesContainer, channelUrl, listQuery);
        channel.markAsRead();
        yield put({
          type: actions.getMessagesAction.success,
          payload: messageReceive(channel, messages),
          query,
        });
      } else {
        const channels = yield call(sbGroupChannelExist, payload.name);
        if (channels.length) {
          const { query, messages, channel } = yield call(sbGetMessagesContainer, channels[0].url);
          channel.markAsRead();
          yield put({
            type: actions.getMessagesAction.success,
            payload: messageReceive(channel, messages),
            query,
          });
        } else {
          yield put({
            type: actions.getMessagesAction.failure,
            payload: payload.name,
          });
        }
      }
    } catch (err) {
      yield put({
        type: actions.getMessagesAction.failure,
        payload: payload.name,
        err,
      });
    }
  }
}

export function* ensureSendMessage({ payload }: SendMessageAction): Saga<*> {
  const channelUrl = (yield select(selectors.chatThreadSelector)).getIn([payload.threadId, 'url']);
  const data = JSON.stringify(payload.params);
  if (channelUrl) {
    const channel = yield call(sbGetGroupChannel, channelUrl);
    const message = yield call(sbSendTextMessage, channel, payload.message, data);
    yield put({
      type: actions.sendMessageAction.success,
      payload: messageReceive(channel, [message]),
    });
  } else {
    const channel = yield call(sbCreateGroupChannelByName, payload.threadId);
    const message = yield call(sbSendTextMessage, channel, payload.message, data);
    yield put({
      type: actions.sendMessageAction.success,
      payload: messageReceive(channel, [message]),
    });
  }
}

// eslint-disable-next-line max-len
export function* receiveMessage({ payload }: { payload: ThreadsItemType }): Saga<*> {
  const activeChannel: string = yield select(selectors.activeChannelSelector);
  const currentCompanyId: string = yield select(currentCompanySelector);
  const userID: string = yield select(userIdSelector);

  if (activeChannel !== payload.name) {
    const senderID = payload.messages.first().getIn(['sender', 'userId']);

    if (payload.has('documentId')) {
      // eslint-disable-next-line prefer-destructuring
      // const company = yield select(companyNameSelector(payload.companyId));
      const companies = yield select(companiesByIdSelector);
      const cname = companies.getIn([payload.companyId, 'cname']);
      if (typeof cname === 'string') {
        if (senderID !== userID) {
          // eslint-disable-next-line max-len
          notifyWithToast(payload.name, payload.messages.first(), cname, payload.companyId === currentCompanyId);
        }
      } else {
        notify.captureEvent(
          new Error("Company have not exists in company's list"),
          {
            data: {
              channel: payload,
              companies: companies.reduce((a, v) => ({ ...a, id: v.id, cname: v.cname }), {}),
            },
          },
          'CHAT_WRONG_COMPANY',
        );
      }
    } else if (senderID !== userID) {
      notifyWithToast(payload.name, payload.messages.first());
    }
  }
}

export function* ensureGetPreviousMessage({ payload }: { payload: { name: string } }): Saga<void> {
  const query: ?PreviousMessageListQuery = yield call(getMessageQuery, payload.name);
  const channelUrl = (yield select(selectors.chatThreadSelector)).getIn([payload.name, 'url']);
  if (channelUrl && query && query.hasMore && !query.isLoading) {
    try {
      const messages = yield call(sbGetMessageList, query);
      const channel = yield call(sbGetGroupChannel, channelUrl);
      yield put({
        type: actions.getPreviousMessageAction.success,
        payload: messageReceive(channel, messages),
        query,
      });
    } catch (err) {
      yield put({
        type: actions.getPreviousMessageAction.failure,
        err,
      });
    }
  }
}

// eslint-disable-next-line max-len
export function* ensureTerminateChannel({
  documentId,
  companyId,
}: {
  companyId: string,
  documentId: string,
}): Saga<void> {
  const thread = (yield select(selectors.chatThreadSelector))
    .toList()
    .filter((f) => f.companyId === companyId && f.documentId === documentId);
  try {
    yield all(thread.reduce((a, v) => a.push(sbMarkAsReadByURL(v.url)), []));
    yield put({
      type: actions.terminateChannelsAction.success,
      payload: thread.reduce((a, v) => a.concat(v.name), []),
    });
  } catch (err) {
    console.warn(err);
  }
}

export function* ensureAllChatChannel() {
  const params = {};

  // eslint-disable-next-line require-yield
  function* prepare() {
    return params;
  }
  return {
    apiName: 'getChatMessages',
    config: {
      params: yield call(prepare),
    },
    interval: 30000,
    prepare,
    success(data) {
      return {
        type: actions.chatGetWorkerThreadsAction.success,
        payload: flow(ChatWorkerThreadsAdapter, partial(channelsToThreads, [CONSTANTS.BUILD_ENV]))(data),
      };
    },
    failure(err) {
      return logoutAction(actions.chatGetThreadsAction, err);
    },
  };
}

export { actions, selectors };
