/* @flow */
import * as React from 'react';
import MatchingArrow from 'components/Tables/grid/matchingArrow';
import { coupledDebounce } from 'components/Tables/helpers';
import cx from 'classnames';
import { withStyles } from '@mui/styles';
import type { MetaType } from '../types.js.flow';
import elements from 'components/elements';
import sheet from './sheet';

export type StyleType = string | {| [key: string]: string |} | void;

export type TableProps = {|
  classes: {
    [key: string]: string,
  },
  meta: MetaType,
  active: ?string,
  className?: string,
  prevItem?: (r: number) => ?React$Node,
  getItem: (r: number, c: string) => React$Node,
  getRowStyle: (r: number) => StyleType,
  getCollStyle: (c: string, r: number) => StyleType,
  colgroup: () => React$Node,
  setHovered: (r: ?number, s: boolean) => void,
  tableRef?: (el: ?HTMLElement) => void,
  prevElRef?: (el: ?HTMLElement) => void,
  markFirstRowWithClass: boolean,
  headerLineNumber: number,
  hovered?: number,
  matchingArrowsPosition: ?('left' | 'right'),
  rtl?: boolean,
  lineCount: ?number,
|};

const isLastOf = (data: any[], index: number): boolean => data.length - 1 === index;

class GridTable extends React.Component<TableProps> {
  handleEnter = (hovered: number) => {
    const { setHovered } = this.props;
    setHovered(hovered, true);
  };

  handleLeave = (row: number) => {
    const { setHovered } = this.props;
    setHovered(row, false);
  };

  compoundHoverHandler = coupledDebounce(
    // MouseEnter handler
    this.handleEnter,
    // MouseLeave callback
    this.handleLeave,
    200, // delay
  );

  renderTextractMatchingAnchorRow = ({ row }) => {
    const { rtl, headerLineNumber, hovered } = this.props;
    const style = { position: 'sticky', ...(rtl ? { right: 0 } : { left: 0 }) };

    return (
      <td style={style}>
        <MatchingArrow headerLineNumber={headerLineNumber} hovered={hovered} row={row} />
      </td>
    );
  };

  render() {
    const {
      classes,
      meta,
      prevItem,
      className,
      colgroup,
      getRowStyle,
      tableRef,
      prevElRef,
      markFirstRowWithClass,
      getItem,
      getCollStyle,
      active,
      matchingArrowsPosition,
    } = this.props;

    const TextractMatchingColumn = this.renderTextractMatchingAnchorRow;

    return (
      <table
        className={cx(classes.table, className)}
        cellSpacing="0"
        data-element={elements.je.container}
        ref={tableRef}
      >
        {matchingArrowsPosition === 'left' && <col width="0" />}
        {colgroup()}
        {matchingArrowsPosition === 'right' && <col width="0" />}
        <tbody>
          {meta[1].map((r, rIndex) => (
            <tr
              key={r.toString()}
              className={cx(classes.row, getRowStyle(r), {
                [classes.firstRowOfTable]: rIndex === 0 && markFirstRowWithClass,
                'first-row-of-table': rIndex === 0,
                'last-row-of-table': isLastOf(meta[1], rIndex),
              })}
              onMouseEnter={this.compoundHoverHandler.enter(r)}
              onMouseLeave={this.compoundHoverHandler.leave(r)}
            >
              {matchingArrowsPosition === 'left' && <TextractMatchingColumn row={r} />}
              {prevItem ? (
                <td ref={prevElRef} className={classes.removeLine}>
                  {prevItem(r)}
                </td>
              ) : null}
              {meta[0].map((c) => (
                <td
                  key={c}
                  className={cx(
                    classes.col,
                    {
                      [classes.active]: active === c + r,
                      'first-cell-of-column': rIndex === 0,
                      'last-cell-of-column': isLastOf(meta[1], rIndex),
                    },
                    getCollStyle(c, r),
                  )}
                >
                  {getItem(r, c)}
                </td>
              ))}
              {matchingArrowsPosition === 'right' && <TextractMatchingColumn row={r} />}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

GridTable.defaultProps = {
  getItem: () => null,
  getRowStyle: () => null,
  getCollStyle: () => null,
  colgroup: () => null,
  setHovered: () => null,
  tableRef: () => null,
  markFirstRowWithClass: false,
  matchingArrowsPosition: null,
};

export default withStyles(sheet)(GridTable);
