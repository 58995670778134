// @flow
import _ from 'lodash';
import { Set, OrderedSet, type Map, List, Record, Range, type RecordFactory, type RecordOf } from 'immutable';
import {
  ExtraPolygonBBFactory,
  ReacordPolyCellSet,
  matchCell,
  type FloatCellType,
  type ListCellType,
  type StringCellType,
  type JornalEntryType,
  type EntriesType,
} from 'domain/journal/helper';
import { createArgsMemoFn } from 'lib/propHelpers';
import { itemAdapter } from 'domain/journal/adapters';
import type { ReferenceType } from 'domain/journal/types.js.flow';
import type {
  JornalEntryStore,
  OptionsItemType,
  MetaType,
  ArrowKeyCode,
  ListItemType,
  EnterKeyCode,
  CellDataPolygonType,
  CellDataMultiPolygonType,
  JeType,
} from './types.js.flow';

export type CombinedMetaRowInfo = {|
  areaIndex: number,
  rowMetaIndex: number,
  rowAreaIndex: number,
  cols: Array<string>,
|};

function mergeCell(a: [Set<string>, Set<number>], b: [string, number]): [Set<string>, Set<number>] {
  return [a[0].add(b[0]), a[1].add(b[1])];
}

export function getMeta(data: Map<string, EntriesType>, rtl?: boolean): MetaType {
  if (data.size === 0) {
    throw new TypeError('Data must be not empty');
  }
  const column = (set: Set<string>): string[] => {
    const ar = [...set];
    return rtl ? ar.reverse() : ar;
  };
  const meta = data.reduce((a, v) => mergeCell(a, matchCell(v._cell)), [new OrderedSet(), new OrderedSet()]);
  const rowMax = [...meta[1]].sort((a, b) => a - b)[meta[1].size - 1];
  return [column(meta[0]), Range(1, rowMax + 1).toArray()];
}

export function getMetaRange(data: JornalEntryType, rtl?: boolean): MetaType {
  const column = (set: Set<string>): string[] => {
    const ar = [...set];
    return rtl ? ar.reverse() : ar;
  };
  const meta = data.reduce((a, v) => mergeCell(a, matchCell(v._cell)), [new OrderedSet(), new OrderedSet()]);
  const rows = Array.from(meta[1]).sort((a, b) => a - b);
  if (rows.length) {
    const rowMax = rows[rows.length - 1];
    const rowMin = rows[0];
    return [column(meta[0]), Range(rowMin, rowMax + 1).toArray()];
  }
  return [column(meta[0]), []];
}

export const OptionItemFactory: RecordFactory<ListItemType> = new Record({
  id: '',
  value: '',
  display: '',
  isActive: true,
});

export function valueNormalize({ id, value }: OptionsItemType | ListItemType): string {
  const ar = value && value.length ? [id].concat([value]) : [id];
  return ar.join(' - ');
}

export function textMapValueNormalize({ value }: OptionsItemType | ListItemType): string {
  return value;
}

export const keyCodeCharacter: Set<number> = Set([
  32, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82,
  83, 84, 85, 86, 87, 88, 89, 90, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 109, 110, 111, 186, 187, 188,
  189, 190, 191, 192, 219, 220, 221, 222,
]);

export const keyCodeArrow: Set<number> = Set([37, 38, 39, 40]);

export function filterList(list: List<OptionsItemType>, term: string): List<OptionsItemType> {
  const compare = (value: string) => String(value).toUpperCase().indexOf(String(term).toUpperCase()) === 0;
  return list.filter((f) => compare(f.value) || compare(f.id));
}

// eslint-disable-next-line max-len
export const getNeighbourCell = (cellChain: Array<string>, direction: 'prev' | 'next', currentCell: string): string => {
  const delta = direction === 'prev' ? -1 : +1;
  const lastIndex = cellChain.length - 1;
  const currentIndex = cellChain.indexOf(currentCell);
  const neighbourIndex = _.cond([
    [(i: number) => i > lastIndex, _.constant(0)],
    [(i: number) => i < 0, _.constant(lastIndex)],
    [_.stubTrue, _.constant(currentIndex + delta)],
  ])(currentIndex + delta);
  return cellChain[neighbourIndex];
};

export const checkCellReadonly = (cellName: string, data: JornalEntryStore): boolean =>
  data.getIn([cellName, 'readonly'], true);

// eslint-disable-next-line max-len
export const getMetaRowCellsChain = (row: string, cols: Array<number>, data: JornalEntryStore) =>
  cols.reduce((res, colName) => {
    const cellName = `${colName}${row}`;
    return checkCellReadonly(cellName, data) ? res : [...res, cellName];
  }, []);

// eslint-disable-next-line max-len
export const getMetaCellsChain = ([cols, rows]: MetaType, data: JornalEntryStore, rtl: boolean) => {
  const rowDirection = rtl ? _.reverse : _.identity;
  return rows.reduce((res, row) => [...res, ...rowDirection(getMetaRowCellsChain(row, cols, data))], []);
};

// eslint-disable-next-line max-len
const getAllCellsChain = createArgsMemoFn((cMeta: Array<MetaType>, data: JornalEntryStore, rtl: boolean) =>
  cMeta.reduce((res, meta) => [...res, ...getMetaCellsChain(meta, data, rtl)], []),
);

// eslint-disable-next-line max-len
export const getMetaColumnCellsChain = (colName: string, rows: Array<number>, data: JornalEntryStore) =>
  rows.reduce((res, row) => {
    const cellName = `${colName}${row}`;
    return checkCellReadonly(cellName, data) ? res : [...res, cellName];
  }, []);

// eslint-disable-next-line max-len
export const getMetaColumnCellsChainByPriorities = (
  colNamePrioritiets: Array<string>,
  rows: Array<number>,
  data: JornalEntryStore,
): Array<string> =>
  colNamePrioritiets.reduce((res, colName) => (res.length ? res : getMetaColumnCellsChain(colName, rows, data)), []);

export const getCollNamePrioritiets = (cols: Array<string>, colEntry: number): string => {
  if (colEntry >= cols.length - 1) {
    return [...cols].reverse();
  }
  const left = [...cols].splice(0, colEntry).reverse();
  const right = [...cols].splice(colEntry + 1);

  const priority = left.reduce((res, leftCol) => {
    const rightCol = right.shift();
    const pair = rightCol ? [leftCol, rightCol] : [leftCol];
    return [...res, ...pair];
  }, []);
  return [cols[colEntry], ...priority, ...right];
};

export const getColEntryByCellName = (cMeta: Array<MetaType>, cellName: string): number => {
  const [col, row] = matchCell(cellName);
  return cMeta.reduce((res, [cols, rows]) => (rows.includes(row) ? cols.indexOf(col) : res), 0);
};

// eslint-disable-next-line max-len
const getAllColumnCellsChain = createArgsMemoFn((colEntry: string, cMeta: Array<MetaType>, data: JornalEntryStore) =>
  cMeta.reduce((res, [cols, rows]) => {
    const colNamePrioritiets = getCollNamePrioritiets(cols, colEntry);
    return [...res, ...getMetaColumnCellsChainByPriorities(colNamePrioritiets, rows, data)];
  }, []),
);

// eslint-disable-next-line max-len
export function getNext(
  keyCode: ArrowKeyCode | EnterKeyCode,
  rtl: boolean,
  isPriority: boolean,
  cMeta: Array<MetaType>,
  data: JornalEntryStore,
  currentCellName: string,
) {
  const allowedKeyCodes = [13, 37, 38, 39, 40];
  if (allowedKeyCodes.includes(keyCode)) {
    // vertical navigation
    if ([38, 40].includes(keyCode)) {
      const colEntry = getColEntryByCellName(cMeta, currentCellName);
      const vChain = getAllColumnCellsChain(colEntry, cMeta, data);
      const vDirection = keyCode === 40 ? 'next' : 'prev';
      return getNeighbourCell(vChain, vDirection, currentCellName);
    }
    // horizontal navigation
    const hChain = getAllCellsChain(cMeta, data, !rtl && isPriority);
    const changeDirection = (rtl || isPriority) && keyCode !== 13 ? (v) => !v : _.identity;
    const hDirection = changeDirection([39, 13].includes(keyCode)) ? 'next' : 'prev';
    return getNeighbourCell(hChain, hDirection, currentCellName);
  }
  throw new Error('No key arrow');

  // 38 UP
  // 40 DOWN
  // 39 RIGHT
  // 37 LEFT
  // 13 ENTER
  // 27 ESC
}

export function checkOptions(opt: ?List<*>): boolean %checks {
  return Boolean(opt) && List.isList(opt);
}

const matchCellDataForListByText = (text) => (e: { display: string }) =>
  e.display.toUpperCase().indexOf(text.toUpperCase()) > -1;

// eslint-disable-next-line max-len
export function checkCallDataSupport(
  { text, type }: RecordOf<ReferenceType>,
  data: FloatCellType | ListCellType | StringCellType,
  getCachedPaginationList: (id: string) => List<OptionsItemType> | void,
): boolean {
  if (type === 'GRID' && ['string', 'float'].includes(data.type)) return true;
  if (!text.length) return false;
  if (data.type === 'float') {
    return true; // isFloat(value);
  }
  if (data.type === 'index_list' && !data.isCreatable) {
    return data._options.some(matchCellDataForListByText(text));
  }
  if (data.type === 'paginated_list' && !data.isCreatable) {
    const cachedList = getCachedPaginationList(data.include_id);
    if (cachedList) {
      return cachedList.some(matchCellDataForListByText(text));
    }
  }
  return true;
}

// eslint-disable-next-line max-len
export function createPolygonReferens(
  cell: string,
  { payload }: $ReadOnly<CellDataPolygonType>,
  polygons,
  { boundingPoly },
) {
  // $FlowFixMe
  const { x, y, h, w } = polygons.get(payload.id).boundingPoly;

  return {
    cell,
    cellSet: ReacordPolyCellSet({
      value: payload.text,
      boundingPoly: ExtraPolygonBBFactory({ x, y, h, w, page_number: boundingPoly.page_number }),
    }),
  };
}

// eslint-disable-next-line max-len
export function createMultiPolygonRefernce(
  cell: string,
  { payload }: $ReadOnly<CellDataMultiPolygonType>,
  __,
  { boundingPoly },
) {
  return {
    cell,
    cellSet: ReacordPolyCellSet({
      value: payload.text,
      boundingPoly,
    }),
  };
}

export function removeLine(r: number, data: JornalEntryType, selectedRows: Set<number>) {
  return data.reduce((a, v, k) => {
    // eslint-disable-next-line
    const [col, row] = matchCell(k);
    return row === r && v.has('cellSet') ? { ...a, ...itemAdapter(v, selectedRows, null) } : a;
  }, {});
}

export const range = (start: number, end: number) => {
  if (end < start) return [];
  return start === end ? [start] : [start, ...range(start + 1, end)];
};

export const isStatement: (x: JeType) => boolean = (jeType) => ['bank_statement', 'reconcile'].includes(jeType);

export const toggleDate = (date?: ?string): ?string => {
  if (typeof date !== 'undefined' && date !== null && date.length > 0) {
    const [dd, mm, yyyy] = date.split('/');
    return [mm, dd, yyyy].join('/');
  }
  return null;
};

export const isDateToggleble = (date?: ?string): boolean => {
  if (typeof date !== 'undefined' && date !== null && date.length > 0) {
    const [dd, mm] = date.split('/');
    return parseInt(dd, 10) <= 12 && parseInt(mm, 10) <= 12;
  }
  return false;
};

// allows for onMouseEnter event handler to be debounced and executed only once pointer remains on target element
// for longer then delay parameter meanwhile if pointer leaves target element earlier, handler execution is cancelled
// in case handler was executed though, leaveCallback will be invoked, which is basically onMouseLeave event handler
export function coupledDebounce(enterCallback, leaveCallback, delay) {
  let timeoutId;
  let hasExecuted = false;

  return {
    enter: (args) => () => {
      clearTimeout(timeoutId);
      hasExecuted = false;

      timeoutId = setTimeout(() => {
        enterCallback.apply(this, [args]);
        hasExecuted = true;
      }, delay);
    },
    leave: (args) => () => {
      if (!hasExecuted) {
        // If handler hasn't fired yet, cancel it
        clearTimeout(timeoutId);
      } else {
        // If handler has already fired, run leave callback
        leaveCallback.apply(this, [args]);
      }
    },
  };
}
